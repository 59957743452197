.s11 {
    font-size: 11px;
}

.s10 {
    font-size: 10px;
}

.s12 {
    font-size: 12px;
}

.s13 {
    font-size: 13px;
}

.s14 {
    font-size: 14px;
}

.s15 {
    font-size: 15px;
}

.s16 {
    font-size: 16px;
}

.s17 {
    font-size: 17px;
}

.s18 {
    font-size: 18px;
}

.s19 {
    font-size: 19px;
}

.s20 {
    font-size: 20px;
}
.s22 {
    font-size: 22px;
}





.w2 {
    font-weight: 200;
}
.w3 {
    font-weight: 300;
}
.w4 {
    font-weight: 400;
}

.w5 {
    font-weight: 500;
}

.w6 {
    font-weight: 600;
}

.w7 {
    font-weight: 700;
}
.w8 {
    font-weight: 800;
}





.cogr1 {
    color: #5D5D5D !important;
}

.cogr2{
    color: #808080;
}

.cor {
    color: #FF3D00 !important;
}
.cor1 {
    color: 
    #A70010 !important;
}
.cor2 {
    color: #CF0014 !important;
}

.bgr1{
    background-color: 
    #A70010;
}




.cog {
    color: #138808;
}

.coo {
    color: #FF9933;
}






.co0 {
    color: #000000;
}

.co1 {
    color: #364050;
}

.co2 {
    color: #426687;
}

.co3 {
    color: #9CA6B4;
}




