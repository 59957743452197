@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap');
.center-data {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .center-y {
    display: flex;
    align-items: center;
  }
  .between-x {
    display: flex;
  justify-content: space-between;
  }
  .center-x {
    display: flex;
    justify-content: center;
  }
  .MuiTabs-scroller{
    overflow:auto !important ;
  }
  * {
    font-family: "Mulish";
    font-optical-sizing: auto; 
    
  }
  

  @media (max-width:768px){
    .input-group{

      padding-left: 0px !important;
      padding-left: auto !important;

    }
  }

  /* event attendent heading

  logos event link */


  .BgImage{
    background-image: url('../src/Assets/image/Rectangle\ 78.png');
    width: 100%;
    height:auto;
    background-repeat:no-repeat;
    padding: 50px 0px 120px 10px  !important;
    background-position: center !important;
    background-size: cover !important;
    color: #D9D9D9 !important; 
    overflow-y: scroll !important;
 }

.css-1aquho2-MuiTabs-indicator
{
  background-color: #F0B137 !important;
}

@media(max-width:768px){
  
  .ABD{
  width:100%  !important; 
  }
.hello{
  margin-top: 0% !important;
}
.css-19kzrtu{
  padding: 0% !important;
}
}

/* CSS for placeholder color */
.custom-input::placeholder {
  color: white !important;
  opacity: 1; /* Ensure it's fully visible */
}
.custom-input:focus {
  border: 1px solid #dee2e6 !important; /* Red border on focus */
  border-right:none !important;
}

.Demo{
  background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 61.3%, rgba(0, 0, 0, 0.05) 100%) !important;

}




.custom-carousel {
  position: relative !important ;
  width: 90% !important; /* Adjust width to show part of the next image */
  margin: 0 auto ;
  overflow: visible !important; /* Allow part of the next image to be visible */
}

.custom-slide {
  width: 100% !important; /* Adjust to control image width */
  padding: 20px !important; /* Add gap between images */
  border-radius: 10px !important; /* Optional: Add some rounding to images */
  transition: transform 0.5s ease !important; /* Smooth transition */
}
@media(max-width:768px){
  .custom-slide{
    padding: 0px !important;
    margin-top: 20px !important;
    padding: 5px 20px !important
  }
  .hello{
    margin-top:0px !important;
  }
}