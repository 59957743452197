/* for small devices */

.headline1 {
    font-size: 18px;
}

.headline2 {
    font-size: 18px;
}

.aboveLine {
    margin-top: 60px;
}



.belowtext{
    color: #3F5162;
    transition: color 0.3s ease;
}

.maindiv1{
    border-radius: 20px;
    border: 3px solid black;
    background-color: white;
    color: black;
    transition: border 0.3s ease, background-color 0.3s ease, color 0.3s ease;
}


.offerings:hover .maindiv1 {
    border: 3px solid #A70010;
    background-color: rgb(245, 169, 169);
    color: #A70010;
}

.offerings:hover .belowtext {
    color: #A70010;
}










/* for large screens */
@media (min-width: 700px) {
    .headline1 {
        font-size: 30px;
    }

    .headline2 {
        font-size: 22px;
    }

    .aboveLine {
        margin-top: 200px;
    }

}


/* images */


.image-container {
    position: relative;
    width: 100%; /* Set your desired width */
    height: 600px; /* Set your desired height */
    overflow: hidden; /* Ensures that the zoom effect stays within bounds */
    border-radius: 20px;
    padding: 0;
}

.image-container img {
    width: 100%;
    height: 100%;
    object-fit: fill; /* Ensures the image covers the container without distortion */
    transition: transform 0.3s ease; /* Smooth zoom effect */
}

.image-container .overlay-text {
    position: absolute;
    bottom: -100%; /* Start position for sliding text */
    left: 35%;
    transform: translateX(-50%);
    color: white;
    font-size: 18px;
    transition: bottom 0.8s ease; /* Smooth slide-up effect */
    text-align: left;
    z-index: 10; /* Position the text above the image */
}

.image-container::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.8), transparent 50%);
    transition: opacity 0.3s ease;
    opacity: 0;
    z-index: 5; /* Ensure the gradient is above the image but below the text */
}

.image-container:hover img {
    transform: scale(1.05); /* Zooms the image slightly from inside */
}

.image-container:hover .overlay-text {
    bottom: 2%; /* Moves the text to the center */
}

.image-container:hover::before {
    opacity: 1; /* Darkens the bottom part of the image */
}



/* for image 2 */

.image-container1 {
    position: relative;
    width: 100%; /* Set your desired width */
    height: 600px; /* Set your desired height */
    overflow: hidden; /* Ensures that the zoom effect stays within bounds */
    border-radius: 20px;
    padding: 0;
}

.image-container1 img {
    width: 100%;
    height: 100%;
    object-fit: fill; /* Ensures the image covers the container without distortion */
    transition: transform 0.3s ease; /* Smooth zoom effect */
}

.image-container1 .overlay-text1 {
    position: absolute;
    bottom: -100%; /* Start position for sliding text */
    left: 83%;
    transform: translateX(-85%);
    color: white;
    font-size: 18px;
    transition: bottom 0.8s ease; /* Smooth slide-up effect */
    text-align: right;
    z-index: 10; /* Position the text above the image */
    width: 100%;
}

.image-container1::before {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to left, rgba(0, 0, 0, 0.8), transparent 50%);
    transition: opacity 0.3s ease;
    opacity: 0;
    z-index: 5; /* Ensure the gradient is above the image but below the text */
}

.image-container1:hover img {
    transform: scale(1.05); /* Zooms the image slightly from inside */
}

.image-container1:hover .overlay-text1 {
    bottom: 15%; /* Moves the text to the center */
}

.image-container1:hover::before {
    opacity: 1; /* Darkens the bottom part of the image */
}



.search-carousel {
    position: relative;
    height: 20px; /* Adjust based on text height */
    overflow: hidden;
  }
  
  .carousel-inner {
    position: absolute;
    top: 0;
    animation: slide 5s infinite;
  }
  
  @keyframes slide {
    0% {
      top: 0;
    }
    33% {
      top: -20px; /* Move up to hide the first item */
    }
    66% {
      top: -40px; /* Move up to hide the second item */
    }
    100% {
      top: 0; /* Reset to first item */
    }
  }
  