.head0{
    background: #FFFFFF;
    box-shadow: 0px 4px 24px 0px #00000014;
    width: 100vw;

}

.round-badge{
    background-color: #CF0014;
    border-radius: 50%;
    font-size: 9px;

}
.huser{
    background-color: #E8E8E840;;
    border-radius: 50%;
}